import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const PREFIX = 'Pagination';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  bullet: `${PREFIX}-bullet`,
  bulletActive: `${PREFIX}-bulletActive`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.wrapper}`]: {
    flexGrow: 2,
    display: 'flex',
    alignItems: 'flex-end',
    margin: theme.spacing(1),
    textAlign: 'center',
  },

  [`& .${classes.bullet}`]: {
    transition: 'all 0.3s',
    transform: 'scale(0.9)',
    display: 'inline-block',
    opacity: 0.6,
    borderRadius: '50%',
    cursor: 'pointer',
    marginRight: 15,
    background: '#ffffff',
    border: '1px solid #E7B607',
    height: 10,
    width: 10,
    '&:last-child': {
      marginRight: 0,
    }
  },

  [`& .${classes.bulletActive}`]: {
    opacity: 1,
    transform: 'scale(1)',
    backgroundColor: '#E7B607',
  }
}));

const Pagination = ({ swiper, activeIndex, slides }) => {

  if(!swiper) return null;

  return (
    <Root className={classes.wrapper}>
    {
      slides.map((s, i) => {
        return (
          <span
            key={s.id}
            className={clsx(
              classes.bullet,
              activeIndex === i && classes.bulletActive
            )}
            onClick={() => swiper.slideTo(i)} />
        );
      })
    }
    </Root>
  );
};

export default Pagination;