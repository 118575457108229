import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useStaticQuery, graphql } from 'gatsby';
import { Button } from 'gatsby-theme-material-ui';
import { Grid, Container } from '@mui/material';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Pagination from './Pagination';
import parse from 'html-react-parser'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import 'swiper/css';

const PREFIX = 'Slider';

const classes = {
  swiper: `${PREFIX}-swiper`,
  slide: `${PREFIX}-slide`,
  backgroundImageWrapper: `${PREFIX}-backgroundImageWrapper`,
  backgroundGradient: `${PREFIX}-backgroundGradient`,
  innerContainer: `${PREFIX}-innerContainer`,
  firstGridItem: `${PREFIX}-firstGridItem`,
  slideContentWrapper: `${PREFIX}-slideContentWrapper`,
  slideTextOuterWrapper: `${PREFIX}-slideTextOuterWrapper`,
  slideTextInnerWrapper: `${PREFIX}-slideTextInnerWrapper`,
  slideTitle: `${PREFIX}-slideTitle`,
  slideDescription: `${PREFIX}-slideDescription`,
  button: `${PREFIX}-button`,
  buttonLabel: `${PREFIX}-buttonLabel`
};

const StyledSwiper = styled(Swiper)((
  {
    theme
  }
) => ({
  [`&.${classes.swiper}`]: {
    height: "100%",
  },

  [`& .${classes.slide}`]: {
    width: '100% !important',
  },

  [`& .${classes.backgroundImageWrapper}`]: {
    display: 'grid', 
    height: '100%',
  },

  [`& .${classes.backgroundGradient}`]: {
    gridArea: "1/1",
    position: "relative",
  // This centers the other elements inside the hero component
    opacity: '1 !important',
    backgroundSize: "cover !important",
    backgroundPosition: '50% 50% !important',
    background: 'radial-gradient(#00000099, transparent)',
    height: '100%',
  },

  [`& .${classes.innerContainer}`]: {
    height: '100%',
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },

  [`& .${classes.firstGridItem}`]: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },

  [`& .${classes.slideContentWrapper}`]: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 4),
    maxWidth: 420,
    height: '100%',
    [theme.breakpoints.up("md")]: {
      maxWidth: 631,
      padding: theme.spacing(1, 0),
      paddingLeft: theme.spacing(1),
    }
  },

  [`& .${classes.slideTextOuterWrapper}`]: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      alignSelf: 'flex-start'
    }
  },

  [`& .${classes.slideTextInnerWrapper}`]: {
    flexGrow: 0.3,
  },

  [`& .${classes.slideTitle}`]: {
    fontFamily: "Prompt",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: '34px',
    lineHeight: '100%',
    color: '#ffffff',
    textShadow: "0px 4px 4px rgba(21, 43, 24, 0.25)",
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: "61px",
      lineHeight: "108%"
    }
  },

  [`& .${classes.slideDescription}`]: {
    fontFamily: "Krub",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: '15px',
    lineHeight: "148.2%",
    color: '#ffffff',
    textShadow: "0px 4px 4px rgba(21, 43, 24, 0.25)",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      lineHeight: "137,2%"
    }
  },

  [`& .${classes.button}`]: {
    [theme.breakpoints.up("md")]: {
      maxWidth: 271
    }
  },

  [`& .${classes.buttonLabel}`]: {
    fontSize: '18px',
    lineHeight: 1.5,
    textTransform: 'lowercase !important',
    fontWeight: 300,
  }
}));

SwiperCore.use([Autoplay])

const Slider = () => {
  const { allWpMainSlide: { slides }} = useStaticQuery(graphql`
    query MainSlides {
      allWpMainSlide {
        slides: nodes {
          title
          id
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          content
          slideDetails {
            buttonLink
            buttonName
            order
          }
        }
      }
    }
  `)

  const [ swiper, setSwiper ] = useState(null)
  const [ activeIndex, setActiveIndex ] = useState(0)

  return (
    <StyledSwiper
    className={classes.swiper}
    slidesPerView={1}
    autoplay={{
      delay: 6000,
      disableOnInteraction: false
    }}
    onSwiper={s => setSwiper(s)}
    onSlideChange={(({ activeIndex}) => setActiveIndex(activeIndex))}
    >
      {
        slides
        .sort((slide1, slide2) => slide1.slideDetails.order - slide2.slideDetails.order)
        .map(slide => {
          const localFile = slide.featuredImage?.node?.localFile;
          
          return (
            <SwiperSlide 
            key={slide.id} 
            className={classes.slide}>
              <div className={classes.backgroundImageWrapper}>
                {
                  localFile &&
                  <GatsbyImage
                  style={{
                    gridArea: "1/1",
                  }}
                  layout="fullWidth"
                  alt={slide.title}
                  image={getImage(localFile)}
                  formats={["auto", "webp", "avif"]}
                  />
                }
                <div className={classes.backgroundGradient}>
                  <Container maxWidth="md" disableGutters style={{ height: '100%'}}>
                    <Grid container justifyContent="center" className={classes.innerContainer}>
                      <Grid item md={2} className={classes.firstGridItem}></Grid>
                      <Grid item md={10}>
                        <div className={classes.slideContentWrapper}>
                          <div className={classes.slideTextOuterWrapper}>
                            <div className={classes.slideTextInnerWrapper}>
                              <h2 className={classes.slideTitle}>{ slide.title }</h2>
                              <div className={classes.slideDescription}>
                                { parse(slide.content) }
                              </div>
                            </div>
                            <Button
                            to={slide.slideDetails?.buttonLink || '/all-price-lists'}
                            variant="contained"
                            color="secondary"
                            classes={{ root: classes.button, label: classes.buttonLabel }}
                            >
                              {slide.slideDetails?.buttonName || 'all price lists'}
                            </Button>
                          </div>
                          <Pagination swiper={swiper} activeIndex={activeIndex} slides={slides} />
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </SwiperSlide>
          )
        })
      }
    </StyledSwiper>
  );
}

export default Slider;